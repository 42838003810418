<template>
  <KSelect v-bind="$attrs" v-on="$listeners" ref="select" :items="items"/>
</template>

<script>
import KSelect from '@/components/crud/fields/KSelect.vue';
import isDisabledTypes from '@/application/enums/isDisabledTypes.js';

export default {
  name: 'IsBlockedSelect',
  components: { KSelect },
  computed: {
    items() {
      return Object.keys(isDisabledTypes).map(type => {
        return {
          value: isDisabledTypes[type],
          text: this.$t(`instruction.isDisabledTypes.${type}`),
        };
      });
    },
  },
};
</script>

<style scoped>

</style>
